



































import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import tag from "@/api/tag.model";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class Enterprise extends Vue {
	model: any = tag;
	title = "Tags de agrupamento";
	isReadOnly: boolean = true;
	defaultEntity = {};
	kind = "editionForm";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowExportButton = false;

	shouldGoToPreviousPageAfterRemove = false;
	onRemoveEntity() {
		this.$root.$emit("refreshTable");
	}

	tableColumns = [{ key: "name" }];

	formColumns = [{ key: "name", isReadOnly: false, required: true }];

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	onBack() {
		this.$router.back();
	}

	async mounted() {}
}
